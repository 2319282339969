import { ryanTransService } from "../../services/RyanTransService/RyanTransService";
import {
  MGTokenBody,
  MGEmailTokenBody,
  MGTokenApiResult,
  MGDeleteApiResult,
} from "./mgTokenTypes";

export const insertMercuryGateToken = async (body: MGTokenBody) => {
  if (!body) return Promise.reject(new Error("Invalid request body"));
  return ryanTransService.post<MGTokenApiResult, MGTokenBody>(`mgToken`)(body);
};

export const emailMercuryGateToken = async (body: MGEmailTokenBody) => {
  if (!body) return Promise.reject(new Error("Invalid request body"));
  return ryanTransService.post<MGTokenApiResult, MGEmailTokenBody>(
    `mgToken/sendMercuryGateTokenEmail`
  )(body);
};

export const deleteMercuryGateUser = async (mgUsername: string) => {
  if (!mgUsername) return Promise.reject(new Error("Invalid request body"));
  return ryanTransService.post<MGDeleteApiResult, {}>(
    `mgToken/mercuryGateDeleteUser/${mgUsername}`
  )({});
};
