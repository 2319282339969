import React, { useCallback, useEffect, useState } from "react";
import {
  addInterceptorsToServices,
  authenticateAndSaveUser,
  authenticateAuth0AndSaveUser,
  clearLocalStorageAuth,
  isAuthenticated,
  isMercuryGateUser,
  redirectToLogin,
  redirectToLogout,
} from "./utils/authUtils";
import { useAuthQueryParams } from "./hooks/useQueryParams";
import LoadingSpinner from "./components/LoadingSpinner";
import MGTokenForm from "./features/mgTokenForm";
import APICallLog from "./features/apiCallLogs";
import AppHeader from "./components/appHeader/AppHeader";
import { Route, Switch, Redirect, NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { featureService } from "./services/featureService/FeatureService";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuth0Enabled, setIsAuth0Enabled] = useState<boolean | null>(null);

  const {
    loginWithRedirect,
    isAuthenticated: isAuth0Auth,
    isLoading: isAuth0Loading,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout,
  } = useAuth0();

  const {
    queryParams: { email, token },
  } = useAuthQueryParams();

  const getFeatureFlags = async () => {
    const featureFlags = await featureService.getFeatures();
    const isAuth0Enabled = featureFlags.includes(
      "15c9b6e221a30bc27ee7f15882126e1bf9ffadc191faa986171a1c88e4e6d237"
    );
    setIsAuth0Enabled(isAuth0Enabled);
  };

  useEffect(() => {
    getFeatureFlags();
  }, []);

  useEffect(() => {
    if (
      !isAuth0Loading &&
      isAuth0Auth &&
      isAuth0Enabled !== null &&
      !isAuth0Enabled &&
      !isAuthenticated()
    ) {
      logout();
    }
  }, [isAuth0Loading, isAuth0Auth, isAuth0Enabled, logout]);

  const loginLegacy = useCallback(async (email, token, isAuth0Enabled) => {
    // if we have an email and token we always need to go revalidate
    // a new token could have been generated when core-web routes to our site
    if (email && token) {
      try {
        await authenticateAndSaveUser(token, email);
        await addInterceptorsToServices(isAuth0Enabled);
        setIsLoading(false);
        return;
      } catch (error) {
        redirectToLogout();
      }
    }
    if (isAuthenticated()) {
      await addInterceptorsToServices(isAuth0Enabled);
      setIsLoading(false);
      return;
    }
    // when we logout core-web will redirect here without the token and email
    // and expects us to redirect back to the proper login url
    redirectToLogin();
  }, []);

  const loginAuth0 = useCallback(
    async (isAuth0Enabled: boolean) => {
      if (!isAuth0Auth) {
        clearLocalStorageAuth();
        await loginWithRedirect({
          appState: {
            returnTo: window.location.pathname,
          },
        });
      }
      await addInterceptorsToServices(
        isAuth0Enabled,
        getAccessTokenSilently,
        logout
      );
      const claims = await getIdTokenClaims();
      const email = claims?.upn || claims?.email;
      await authenticateAuth0AndSaveUser(email);
      setIsLoading(false);
    },
    [
      isAuth0Auth,
      loginWithRedirect,
      getAccessTokenSilently,
      logout,
      getIdTokenClaims,
    ]
  );

  useEffect(() => {
    const startAuth = async () => {
      if (isAuth0Enabled === null || isAuth0Loading) return;
      if (isAuth0Enabled && !isAuthenticated()) {
        await loginAuth0(isAuth0Enabled);
      } else {
        await loginLegacy(email, token, isAuth0Enabled);
      }
    };
    startAuth();
  }, [isAuth0Loading, isAuth0Enabled, loginAuth0, loginLegacy, email, token]);

  if (isLoading || isAuth0Enabled === null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <AppHeader isAuth0Enabled={isAuth0Enabled}>
        {isMercuryGateUser() && (
          <>
            <NavLink
              to="/tokenGeneration"
              className="navItem"
              activeClassName="active"
            >
              Token Generation
            </NavLink>
            <NavLink
              to="/apiCallLog"
              className="navItem"
              activeClassName="active"
            >
              API Call Log
            </NavLink>
          </>
        )}
      </AppHeader>
      <Switch>
        <Route path="/tokenGeneration" component={MGTokenForm}></Route>
        <Route path="/apiCallLog" component={APICallLog}></Route>
        <Redirect from="/" to="/tokenGeneration" />
      </Switch>
    </>
  );
}

export default App;
