import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import "./index.css";
import App from "./App";
import { CloverThemeProvider } from "shamrock-clover-ui/dist/clover/Theme/ThemeProvider";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN!} //login domain 'auth.rtspro.dev', should be the same for all teams regardless of application
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!} //clientId of the application specified above
    authorizationParams={{
      redirect_uri: window.location.origin, //location of your app rtspro.dev
      audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`, // management api url 'https://stc-development.us.auth0.com/api/v2/'
      scope: "openid profile email offline_access",
    }}
  >
    <CloverThemeProvider>
      <Router>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Router>
    </CloverThemeProvider>
  </Auth0Provider>,
  document.getElementById("root") as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
