import Service from "../Service";
import { AxiosResponse } from "axios";

class FeatureService extends Service {
  constructor() {
    super(process.env.REACT_APP_FEATURE_SERVICE_URL || "");
  }

  async getFeatures(): Promise<Array<string>> {
    const response: AxiosResponse = await this.axios.get(`/active`);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }
}

export const featureService = new FeatureService();
