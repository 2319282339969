import Service from "../Service";
import { AxiosResponse } from "axios";

class RyanTransService extends Service {
  constructor() {
    super(process.env.REACT_APP_RYAN_TRANS_SERVICE_URL || "");
  }

  private handleResponse<T>(response: AxiosResponse): T {
    if (!response || response.status !== 200) {
      throw new Error(`API request failed with status ${response?.status}`);
    }
    return response.data;
  }

  public get =
    <ReturnType>(url: string) =>
    async (query?: string): Promise<ReturnType> => {
      const formattedUrl = query ? `/${url}${query}` : `/${url}`;
      const response: AxiosResponse = await this.axios.get(formattedUrl);
      return this.handleResponse(response);
    };

  public post =
    <ReturnType, BodyType>(url: string) =>
    async (body: BodyType): Promise<ReturnType> => {
      const response: AxiosResponse = await this.axios.post(url, body);
      return this.handleResponse(response);
    };

  public put =
    <ReturnType, BodyType>(url: string) =>
    async (body: BodyType): Promise<ReturnType> => {
      const data = JSON.stringify(body);
      const response: AxiosResponse = await this.axios.put(url, data);
      return this.handleResponse(response);
    };
}

export const ryanTransService = new RyanTransService();
