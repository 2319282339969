import { ryanTransService } from "../../services/RyanTransService/RyanTransService";

type MGTokenResponse = {
  data: {
    json():
      | { token: string; clientCode: string }
      | PromiseLike<{ token: string; clientCode: string }>;
    token: string;
    clientCode: string;
  };
};

export const getTokenByUsername = async (mgUsername: string) => {
  let res: MGTokenResponse;
  res = await ryanTransService.get<MGTokenResponse>(`mgToken/${mgUsername}`)();
  console.log(res.data);
  return res.data;
};
