import Service from "../Service";
import { AxiosResponse } from "axios";

export interface User {
  _id: string;
  firstname: string;
  lastname: string;
  permissions: { name: string; status: string }[];
  user_type: string;
  status: string;
}
export class RtsProUsersService extends Service {
  constructor() {
    super(process.env.REACT_APP_RTS_USERS_SERVICE_URL || "");
  }

  async getUserByEmail(email: string): Promise<{ user: User }> {
    const url = `/`;

    const body = {
      query: `query user($email: String) {
      user(email: $email) {
        _id
        firstname
        lastname
        email
        user_type
        status
        permissions { name, status }
        }
      }`,
      variables: {
        email,
      },
    };

    const response: AxiosResponse = await this.axios.post(url, body);
    console.log("response", response);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }
}

export const usersService = new RtsProUsersService();
