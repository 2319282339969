import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { UserService } from "../../services";
import {
  getAuthToken,
  redirectToLogin,
  redirectToLogout,
} from "../../utils/authUtils";
import { styled } from "shamrock-clover-ui/dist/clover/Theme/Theme";
import {
  FirebaseEvents,
  FirebaseModules,
  FirebasePages,
  logFirebaseEvent,
} from "../../utils/firebaseUtils";
import "./appHeader.scss";
import { useAuthQueryParams } from "../../hooks/useQueryParams";
import { useAuth0 } from "@auth0/auth0-react";

export const LoginLink = styled.a`
  color: #666666;
  align-self: center;
  margin-left: auto;
`;

interface Props {
  isAuth0Enabled: boolean;
  useRTSProLogo?: boolean;
  ignoreClick?: boolean;
}

// const Config = require("Config");

const Header: React.FC<Props> = ({ isAuth0Enabled, children }) => {
  const [anchorEl, setAnchorEl] = React.useState<
    HTMLDivElement | null | undefined
  >(undefined);
  const { logout } = useAuth0();
  const { clearQueryParams } = useAuthQueryParams();

  const user = UserService.getCurrentUser();
  const firstName = user?.firstname || "";
  const lastName = user?.lastname ? ` ${user?.lastname}` : "";

  const handleSettingsClicked = () => {
    const settingsUrl = `${process.env.REACT_APP_RTS_PRO_URL}settings`;
    window.location.assign(settingsUrl);
  };

  const handleLogoutClicked = () => {
    setAnchorEl(null);
    clearQueryParams();
    const isLegacyToken = getAuthToken();
    if (!isLegacyToken) {
      redirectToLogout(
        logout({
          logoutParams: {
            returnTo: `${process.env.REACT_APP_MERCURYGATE_TOKEN_URL}`,
          },
        })
      );
    } else {
      redirectToLogout();
    }
  };

  const loginClick = () => {
    logFirebaseEvent(
      FirebaseEvents.LOGIN,
      FirebaseModules.EASYTRACK,
      FirebasePages.EASYTRACK,
      {}
    );
    if (isAuth0Enabled) {
      redirectToLogout(
        logout({
          logoutParams: {
            returnTo: `${process.env.REACT_APP_MERCURYGATE_TOKEN_URL}`,
          },
        })
      );
    } else {
      redirectToLogin();
    }
  };

  return (
    <div className="header">
      <div className="appBarTopMenu">
        <div className="toolBar">
          <div className="rightSideHeader">
            {user ? (
              <div
                className="menuItem"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                {firstName + lastName}
                <div
                  className={
                    anchorEl === undefined ? "" : anchorEl ? "open" : "closed"
                  }
                >
                  <ArrowDropDownIcon />
                </div>
              </div>
            ) : (
              <LoginLink onClick={loginClick}>Log In</LoginLink>
            )}
          </div>
          <Menu
            classes={{ paper: "menuRoot" }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={handleSettingsClicked}>
              <div className="menuItem">Settings</div>
            </MenuItem>
            <MenuItem onClick={handleLogoutClicked}>
              <div className="menuItem">Log Out</div>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="appBarPrimaryNavigation">
        <div className="flex">{children}</div>
      </div>
    </div>
  );
};

export default Header;
